<template>
  <div class="background">
    <v-card v-if="!canReportQuantity" rounded="lg" class="mb-6">
      <v-card-text class="d-flex align-center" style="column-gap: 20px">
        <cz-icon :size="32" :src="mdiAlertOutline" color="negative" />
        <div class="text-body-1 font-weight-semibold">
          {{ $t('vendor.orderSubmissions.quantityReportAlertMessage') }}
        </div>
      </v-card-text>
    </v-card>
    <v-card
      flat
      class="d-flex flex-column"
      style="gap: 25px"
      :style="{
        opacity: !canReportQuantity ? 0.5 : 1
      }"
    >
      <v-card-text>
        <cz-data-table
          hide-default-footer
          :columns="columns"
          :items="submission.submissionItems"
          style="background-color: transparent"
        >
          <template #item.supplyDate="{ item }">
            {{ formatDateToDisplay(item.orderItem.supplyDate, 'dd/MM/yyyy') }}
          </template>
          <template #item.pricePerUnit="{ item }" v-if="submission">
            {{
              formatAmountWithCurrency(
                item.orderItem.pricePerUnit,
                submission.currency
              )
            }}
          </template>
          <template #item.totalAmount="{ item }" v-if="submission">
            {{
              formatAmountWithCurrency(
                item.orderItem.totalAmount,
                submission.currency
              )
            }}
          </template>
          <template #item.currentQuantity="{ item }">
            <validation-provider
              :rules="`max_value:${item.orderItem.quantityLeft}`"
              :name="item.orderItem.catalogItem.itemName"
              v-slot="{ errors }"
            >
              <cz-input
                v-model="item.quantity"
                v-if="item.orderItem.quantityLeft > 0"
                dense
                :hide-details="!errors || !errors.length"
                type="number"
                :error-messages="errors"
                :readonly="readonly"
                style="width: 75px"
                :placeholder="$t('vendor.orderSubmissions.enterQuantity')"
                :disabled="item.quantityLeft === 0"
              />
            </validation-provider>
          </template>
          <template #item.currentAmount="{ item }">
            {{
              formatAmountWithCurrency(
                parseFloat(item.quantity || 0) *
                  parseFloat(item.orderItem.pricePerUnit),
                submission.currency
              )
            }}
          </template>
          <template #footer>
            <v-footer class="d-flex justify-end mt-4">
              <div class="d-flex" style="gap: 20px">
                <div
                  class="d-flex align-center justify-space-between text-body-1 font-weight-semibold"
                  style="gap: 20px"
                >
                  <div>{{ $t('common.total') }}:</div>
                  <span>{{
                    formatAmountWithCurrency(
                      currentSubmissionAmount,
                      submission.currency
                    )
                  }}</span>
                </div>

                <v-divider vertical />

                <div
                  class="d-flex align-center justify-space-between text-body-1 font-weight-semibold"
                  style="gap: 20px"
                >
                  <div>{{ $t('common.vat') }}:</div>
                  <span>{{
                    formatAmountWithCurrency(
                      currentSubmissionAmount * vendorVat,
                      submission.currency
                    )
                  }}</span>
                </div>
                <v-divider vertical />
                <div
                  class="d-flex align-center justify-space-between text-body-1 font-weight-semibold"
                  style="gap: 20px"
                >
                  <div>{{ $t('common.totalWithVat') }}:</div>
                  <span>{{
                    formatAmountWithCurrency(
                      currentSubmissionTotalAmount,
                      submission.currency
                    )
                  }}</span>
                </div>
              </div>
            </v-footer>
          </template>
        </cz-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { CzDataTable, CzInput, CzIcon } from '@/components';
import { formatDateToDisplay } from '@/shared/services/dates.service';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';
import { mdiAlertOutline } from '@mdi/js';
const cellClass = 'py-2';
export default {
  name: 'SubmissionItems',
  components: {
    CzDataTable,
    CzInput,
    CzIcon
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    vendorVat: {
      type: Number,
      required: true
    },
    readonly: {
      type: Boolean
    }
  },
  computed: {
    orderItems() {
      return this.submission?.relatedOrder?.orderItems || [];
    },
    vendorVatPercent() {
      if (this.vendorVat > 0) {
        return `${this.vendorVat * 100}%`;
      }
      return this.$t('customer.orders.noVat');
    },
    canReportQuantity() {
      return this.submission?.netAmount > 0 && this.submission?.totalAmount > 0;
    },
    submissionAmountLeft() {
      return (
        parseFloat(this.submission.netAmount) -
        parseFloat(this.currentSubmissionAmount)
      );
    },
    currentSubmissionAmount() {
      // calculate the current submission amount
      let amount = 0;

      if (!this.submission) {
        return amount;
      }

      this.submission.submissionItems.forEach((submissionItem) => {
        // calculate the amount by multiple it with the price per unit
        if (parseFloat(submissionItem.quantity) > 0) {
          amount =
            amount +
            parseFloat(submissionItem.quantity) *
              parseFloat(submissionItem.orderItem.pricePerUnit);
        }
      });

      return amount;
    },
    currentSubmissionTotalAmount() {
      const amount = this.currentSubmissionAmount;
      const vat = parseFloat(amount) * parseFloat(this.vendorVat);
      return amount + vat;
    }
  },
  data() {
    return {
      mdiAlertOutline,
      submission: null,
      columns: [
        {
          text: this.$t('customer.orders.orderItemsPage.rowNumber'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'rowNumber',
          cellClass,
          sortable: false
        },
        {
          text: this.$t('customer.orders.orderItemsPage.itemName'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          cellClass,
          value: 'orderItem.catalogItem.itemName',
          sortable: false
        },
        {
          text: this.$t('customer.orders.orderItemsPage.supplyDate'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'supplyDate',
          cellClass,
          sortable: false
        },
        {
          text: this.$t('customer.orders.orderItemsPage.unitOfMeasure'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'orderItem.catalogItem.itemUnitOfMeasureText',
          cellClass,
          sortable: false
        },
        {
          text: this.$t('customer.orders.orderItemsPage.quantity'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'orderItem.quantity',
          cellClass,
          sortable: false
        },
        {
          text: this.$t('customer.orders.orderItemsPage.quantityReported'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'orderItem.quantityReported',
          cellClass,
          sortable: false
        },
        {
          text: this.$t('customer.orders.orderItemsPage.quantityLeft'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'orderItem.quantityLeft',
          cellClass,
          sortable: false
        },

        {
          text: this.$t('customer.orders.orderItemsPage.price'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'pricePerUnit',
          cellClass,
          sortable: false,
          width: 100
        },
        {
          text: this.$t('customer.orders.orderItemsPage.totalAmount'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'totalAmount',
          cellClass,
          sortable: false
        },
        {
          text: this.$t('vendor.orderSubmissions.quantityInCurrent'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'currentQuantity',
          cellClass,
          sortable: false
        },
        {
          text: this.$t('vendor.orderSubmissions.totalInCurrent'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'currentAmount',
          cellClass,
          sortable: false
        }
      ]
    };
  },
  methods: {
    formatDateToDisplay,
    formatAmountWithCurrency
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!isEqual(this.submission, value)) {
          this.submission = cloneDeep(value);
        }
      }
    },
    submission: {
      deep: true,
      handler(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
